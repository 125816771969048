::-webkit-scrollbar {
    display: none;
}  

.MuiAutocomplete-tag {
    background-color: red!important;
    color:white!important;
    font-weight: 700;
}
.MuiChip-deleteIcon {
    color: #ffffff9e!important;
}

.amplify-tabs-item[data-state=active] {
    border-color:rgb(227, 0, 21)!important;
    color:rgb(227, 0, 21)!important
}

.amplify-button--primary {
    background-color:rgb(227, 0, 21)!important
}
.amplify-button--small {
    color:rgb(227, 0, 21)!important;
    transition: all ease-in-out 0.2s!important;
}
[data-amplify-authenticator][data-variation=modal] {
    background-color: white!important;
}
.amplify-input:focus {
    box-shadow:0px 0px 2px rgb(227, 0, 21)!important;
    border-color:rgb(227, 0, 21)!important
}

.amplify-button--small:hover {
    background-color: transparent!important;
    font-weight: 700!important;
    transition: all ease-in-out 0.2s!important;
    letter-spacing: 3px!important;
}
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
    color:rgb(227, 0, 21)!important;
}
.css-1a1fmpi-MuiInputBase-root-MuiInput-root:after, .css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
    border-bottom: 2px solid rgb(227, 0, 21)!important;
}
.css-1qqsdnr-MuiAutocomplete-root .MuiAutocomplete-tag {
    margin:0px 10px 0px 0px!important;
}